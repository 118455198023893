import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  
  // {
  //   path: `/menu/:category`,
  //   name: 'menu',
  //   component: () => import('../views/MenuView.vue')
  // },

  {
    path: `/menu`,
    name: 'menu',
    component: () => import('../views/MenuView.vue')
  },

  {
    path: '/actions',
    name: 'actions',
    component: () => import('../views/ActionsView.vue')
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },

  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('../views/DeliveryView.vue')
  },

  {
    path: '/pickup',
    name: 'pickup',
    component: () => import('../views/PickupView.vue')
  },

  {
    path: '/restaurants',
    name: 'restaurants',
    component: () => import('../views/RestaurantsView.vue')
  },

  {
    path: '/combo',
    name: 'combo',
    component: () => import('../views/ComboView.vue')
  },
  
  {
    path: '/offers',
    name: 'offers',
    component: () => import('../views/OffersView.vue')
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('../views/ReviewsView.vue')
  },

  {
    path: '/career',
    name: 'career',
    component: () => import('../views/CareerView.vue')
  },
  
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/ContactsView.vue')
  },
  {
    path: '/contest',
    name: 'contest',
    component: () => import('../views/contestView.vue')
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
