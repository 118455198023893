import {ref} from "vue"
export function useApi() {
    const api = "https://api.franklins.ru/"
    /* const api = "http://192.168.0.119" */

    const store_id = 1
        return {
            api,
            store_id,
        }
}