import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useContacts(){
    const {api, store_id} = useApi();
    const contacts = ref(null)
    const getContacts = ()=>{
        return axios.get(api+`/stores/${store_id}/contacts/`)
        .then((r) =>{
            contacts.value = r.data.data
            //////console.log(contacts);
        })
    }
    return {
        contacts, 
        getContacts,
    }
}