import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useActions(){
    const {api, store_id} = useApi();
    const banners = ref(null)
    const getActions = ()=>{
        return axios.get(api+`/actions/`)
        .then((r) =>{
            banners.value = r.data.data
        })
    }
    return {
        banners,
        getActions,
    }
}