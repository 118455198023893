<template>
    <div class="new-header">
        <div class="new-header__menu">
            <div class="header__navigation active-route">
            <router-link :to="item.link" class="new-header__link" v-for="(item, index) in menuItems" :key="index">{{item.name}}</router-link>
            </div>
        </div>
        <div class="new-header__imgWrap">
            <img src="@/assets/headerLeftBurger.png" class="new-header__img-left"/>
            <img @click="routeMain" src="@/assets/Frame.svg" class="new-header__img-logo"/>
            <img src="@/assets/headerRightBurger.png" class="new-header__img-right"/>
        </div>
    </div>


    <!-- mobile burger menu -->
    <div class="burger__empty">
        
    </div>
    <div class="burger">
        <img @click="routeMain" src="@/assets/burgerMenuBlack.png"  class="burger__logo"/>
        <!-- src="@/assets/Frame.svg" -->
        <div @click="openMenu" class="burger__but">
            <img  src="@/assets/menu.svg" class="burger__img">
        </div> 
        
    </div>
    <div class="burger__menu" :class="{opened:isOpened}">
        <div v-for="(item, index) in menuItems" :key="index" class="burger__row">
            <router-link @click="closeMenu" :to="item.link" class="new-header__link" >{{item.name}}</router-link>
        </div>
    </div>
</template>
<script>
export default {
    emits:[
        "openMenu",
    ],
     data() {
        return {
            menuItems:
            [{name: 'Mеню', link: '/menu'},
                {name: 'О нас', link: '/about'},
                {name: 'Доставка', link: '/delivery'},
                {name: 'Самовывоз', link: '/pickup'},
                {name: 'Рестораны', link: '/restaurants'},
                // {name: 'КОМБО', link: '/combo'},
                {name: 'Спецпредложения', link: '/offers'},
                {name: 'Отзывы', link: '/reviews'},
                {name: 'Карьера', link: '/career'},
                {name: 'Контакты', link: '/contacts'},
                {name: 'Розыгрыш', link: '/contest'}
            ],
            isOpened: false, 
        }
    },
    methods: {
        routeMain(){
            this.$router.push({name: "home"})
            this.closeMenu()
        },
        openMenu(){
            this.isOpened = !this.isOpened;
            if (this.isOpened){
                setTimeout(()=>{this.$emit("openMenu", this.isOpened)}, 500)
                return
            }
            this.$emit("openMenu", this.isOpened)
        },
        closeMenu(){
            this.isOpened = false;
            this.$emit("openMenu", false)
        }
    },
    watch:{
        '$route.path'(value){
            ////console.log("value");
        },
    },
}
</script>
<style lang="scss" src="@/style/newHeader.scss">

</style>