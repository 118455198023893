<template>
<div class="header__empty">
    <div ref="navigationFlag"></div>
    <div class="header" :class="{'header--fixed':isNavigationFixed}">
        <div class="header__logo">
            <img class="header__logo_img" src="../assets/Frame.svg" @click="routeMain"/>
        </div>
        
        <div class="header__navWrap"  >
            <div class="header__navigation active-route">
                <div @click="routeMain" class="header__navigation_littleLogo"><img src='@/assets/miniLogo.svg'/></div>
                
                <div class="header__navigation_link" @mouseenter.passive="openSubMenu(item.name)" @mouseleave="closeSubMenu(name)" v-for="(item, index) in menuItems" :key="index">
                    <router-link  @click.prevent="emitCategory(item.name)" :to="item.link" class="header__navigation_link-a">
                        {{item.name}}
                    </router-link>
                    <div class="header__navigation_link-submenuWrap">
                        <div v-if="item.submenu" :ref="`${item.name}-ref`" class="header__navigation_link-submenu">
                            <a v-for="(link, idx) in item.submenu" @click.prevent="emitCategory(link.name)" :key="idx" :href="link.link"  class="header__navigation_link-submenu-link">{{link.name}}</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>  
</div>

    <!-- mobile burger menu -->
    <div class="burger__empty">
    </div>
    <div class="burger" :class="{'burger--shadow': !isPageMenu, 'burger--dark':isOpened}">
        <img v-if="!isOpened" @click="routeMain" src="@/assets/Frame.svg"  fill="none" class="burger__logo"/>
        <img v-else="" @click="routeMain" src="@/assets/frameLight.svg"  fill="none" class="burger__logo"/>
        <img @click="openMenu" src="@/assets/menu.svg" class="burger__but">
 
        
    </div>
    <div class="burger__menu" :class="{opened:isOpened}">
        <div class="burger__empty"></div>
        <img src="@/assets/miniLogo.svg" class="burger__menu_img">
        <div class="burger__wrap">
            <div v-for="(item, index) in menuItems" :key="index" class="burger__row">
                <router-link @click="closeMenu" :to="item.link" class="header__link" >
                    {{item.name}}
                </router-link>
            </div>
        </div>

        <!-- <div class="burger__bottom">
            <div class="burger__bottom_iconWrap">
                <a href="https://www.delivery-club.ru/srv/FRANKLINS_BURGJeR">
                    <img src="@/assets/delivery.png" class="burger__bottom_iconWrap-img">
                </a>

                <a href="https://eda.yandex.ru/moscow/r/franklins_burger?placeSlug=franklins_burger_skhodnenskaya">
                    <img src="@/assets/yandex.png" class="burger__bottom_iconWrap-img">
                </a>
            </div>
            <div class="burger__bottom_phoneWrap">
                <img src="@/assets/iphone(4).svg" alt="">
                <div class="burger__bottom_phoneWrap-textWrap">
                    <div class="burger__bottom_phoneWrap-textWrap-title">
                        {{mainContacts[0]}}
                    </div>
                    <div class="burger__bottom_phoneWrap-textWrap-sub">
                        Центральный офис
                    </div>
                </div>
            </div>
        </div> -->

    </div>
    

</template>
<script>
import {useContacts} from '@/composition/useContacts.js'
import { onMounted } from '@vue/runtime-core'
import {ref} from 'vue'

import {useCategories} from '@/composition/useCategories.js'
import {useActions} from '@/composition/useActions.js'

export default{
    data() {
        return {
            menuItems:
            [
                {name: 'Mеню', link: '/menu'},
                
                {name: 'Адреса', link: '/restaurants'},
                {name: 'Доставка',link: '/delivery',
                    // submenu:[
                    //     {name: 'Самовывоз', link: '/delivery'}
                    // ]
                },
                // {name: 'Отзывы', link: '/reviews'},
                {name: 'О компании', link: '/about',
                    submenu:[
                        {name: 'Руководство', link: '/about#chiefs'},
                        {name: 'История', link: '/about#History'},
                        {name: 'Преимущества', link: '/about#principles'},
                        {name: 'Пресс-релизы', link: '/about#principles'},
                        {name: 'Наши партнеры', link: '/about#principles'},
                        {name: 'Контакты', link: '/about#principles'},
                       
                    ],
                },
                
                {name: 'Карьера', link: '/career'},
                {name: 'Розыгрыш', link: '/contest'}
                // {name: 'Самовывоз', link: '/pickup'},
                // {name: 'КОМБО', link: '/combo'},
                // {name: 'Спецпредложения', link: '/offers'},
                // {name: 'Контакты', link: '/contacts'},
            ],      
            isOpened: false, 
            isNavigationFixed: true,
            isPageMenu: false,
            actualSubMenu: null,
            actualElement: null,
            categories: null,
            namesList: [],
        }
    },
    created(){
        const {getCategories,  categories} = useCategories()
        getCategories()
        .then(()=>{
            // this.categories = categories.map(item => item.name)
            this.namesList = categories.value.map(element => {
                return {name: element.name}
            });
            this.menuItems.find(item => item.name === 'Mеню').submenu = this.namesList
        })


        const {getActions, banners} = useActions()
        getActions()
        .then(()=>{
            banners.value = banners.value.filter(el => el.visible)
            // console.log(banners.value.length);
            if(banners.value.length != 0){
                console.log(this.menuItems.length);
                this.menuItems.splice(1, 0, {name: 'Акции', link: '/actions'})
                console.log(this.menuItems.length);

            }
        })
    },
    mounted(){
        // ////console.log(this.$route.path);
            window.addEventListener('scroll', this.checkFlag)
    },
    unmounted(){
        window.removeEventListener('scroll', this.checkFlag)
    },
    methods: {
        routeMain(){
            this.$router.push({name: "home"})
            this.closeMenu()
        },
        openMenu(){
            this.isOpened = !this.isOpened;
            if (this.isOpened){
                setTimeout(()=>{this.$emit("openMenu", this.isOpened)}, 500)
                return
            }
            this.$emit("openMenu", this.isOpened)
        },
        closeMenu(){
            this.isOpened = false;
            this.$emit("openMenu", false)
        },
        // ывпХЪ450+-*=-0ХЪ{]}
        // checkFlag(){
        //     if(!this.isPageMenu){                
        //         if(this.$refs.navigationFlag.getBoundingClientRect().top<=0){
        //             this.isNavigationFixed = true //true
        //         }
        //         else{
        //             this.isNavigationFixed = true
        //         }
        //     }
        // },
        emitCategory(value){ 
            if(["Доставка", "Самовывоз"].includes(value)){
                //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
                if(value === "Самовывоз"){
                    this.$router.push({path: '/delivery'})
                }
                this.$emit('clickCategory', value)
            }

            if(["О компании", "Руководство", "История", "Преимущества", "Пресс-релизы", 'Наши партнеры', "Контакты"].includes(value)){
                //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!');
                if(value !== "О компании"){
                    this.$router.push({path: '/about'})
                }
                console.log(value);
                this.$emit('clickCategory', value)
            }
            
            //////console.log(category);
            if(Object.values(this.namesList).map(el => el.name).includes(value)){
                this.$router.push({name:"menu"})
                console.log(value);
                this.$emit('clickCategory', {name: value})
                if(this.$route.name = "Меню"){
                    //console.log("!!");
                }
            }
            this.closeSubMenu()
        },
        openSubMenu(name){
            // let actualPoint = this.$refs[name+'-ref']
            if(this.$refs[name+'-ref']){
                this.actualSubMenu = this.$refs[name+'-ref'][0]                
            }
        },
        closeSubMenu(name){
            this.actualSubMenu = null
        }
    },
    watch:{
        '$route.path'(){
            window.scroll({
                top:0,
                left:0,
            })
            ////console.log(this.$route.name);
            if(this.$route.name!='menu' && this.$route.name!='about'){
                this.isPageMenu = false
                this.isNavigationFixed = true
            }
            else{
                this.isPageMenu = true
                this.isNavigationFixed = false
            }
            
        },
        actualSubMenu(element, oldElement){
            if(element){
                this.actualElement = element
                setTimeout(() => {
                    if(this.actualElement == element){
                        element.style.display = 'flex'
                        setTimeout(()=>{
                            element.style.opacity = 1
                        }, 10)
                    }
                }, 100)
            }
            if(oldElement){
                // setTimeout(()=>{
                    if(oldElement && this.actualSubMenu != oldElement){
                        oldElement.style.opacity = 0
                        setTimeout(()=>{
                            oldElement.style.display = 'none'
                        }, 100)
                    }
                // }, 0)
            }
        }
    },
    setup(){
        const {getContacts, contacts} = useContacts();
        const mainContacts = ref([])
        const buyContacts = ref([])
        const rentContacts = ref([])
        const marketingContacts = ref([])
        const hrContacts = ref([])

        onMounted(()=>{
            let counter = 0
            getContacts().then(()=>{
                //////console.log(contacts);
                contacts.value.forEach(element => {
                    const settings = [
                        {name:"Центральный офис", obj: mainContacts},
                        {name:"Отдел закупок", obj: buyContacts},
                        {name:"Отдел аренды", obj: rentContacts},
                        {name:"Отдел маркетинга", obj: marketingContacts},
                        {name:"HR отдел", obj: hrContacts},
                    ]
                    for(let set in settings){
                        if(element.name === settings[set].name){
                            counter++
                            settings[set].obj.value.push(element.text)
                        }
                    }
                });
                //////console.log(hrContacts);
                //////console.log(counter);
            })
        })
        return {
            contacts,
            mainContacts,
            buyContacts,
            rentContacts,
            marketingContacts,
            hrContacts,
        }
    }
}
</script>

<style lang="scss" src="@/style/header.scss"></style>
