<template :style="{'': !scrollBody}">
  <!-- <newHeaderApp @openMenu='hideContent'/> -->
  <header-app @clickCategory="setCategory" @openMenu='hideContent'></header-app>
  <div class="" style="flex-grow: 10;" :class="{'none':blockContent}">
    <router-view 
      @clickCategory="setCategory"
      :categoryToScroll="category"
      @clearProps="clearProps"
      :isMobileIOS="isMobileIOS"

      @routeToProduct="routeToProduct"
      :productToRoute="productToRoute"

      :PageDelivery="pageDelivery"
      @changePageDelivery="changePageDelivery"
      @clearPropsProductRoute="clearPropsProductRoute"

      :pageAbout="pageAbout"
      @changePageAbout="changePageAbout"


    />
  </div>
  
  <footer-app v-if="!blockContent" @checkSection="checkSection" @clickCategory="setCategory"/>
</template>

<script>
// import headerApp from './/components/headerApp.vue'
import newHeaderApp from './components/NewHeaderApp.vue'
import footerApp from './components/footerApp.vue'
import headerApp from "@/components/headerApp.vue"
// import MapApp from "@/components/headerApp.vue"

export default {
  name: 'App',
  components:{
    // headerApp,
    footerApp,
    newHeaderApp,
    headerApp,
  },
  data(){
    return{
      blockContent: false,
      category:"",
      isMobileIOS: null,
      pageDelivery: 1,
      pageAbout: 1,
      productToRoute: null,
    }
  },
  created(){
        ////console.log(navigator.userAgent);
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            // this.message = 'это iosMObile '+navigator.userAgent
            // ////console.log('это iosMObile');
            this.isMobileIOS = true
        }
        else{
          this.isMobileIOS = false
            // this.message = 'это не iosMObile '+navigator.userAgent
            // ////console.log('это не ios');
        }








        setTimeout(() => {
            console.log("foo")
        }, 0)

        setTimeout(() => {
            const h = new Promise((resolve) => {
              resolve("hui2")
            })
            p.then(r => console.log(r))
            
        }, 0)

        const p = new Promise((resolve) => {
            resolve("bar")
        })

        p.then(r => console.log(r))









  },
  methods:{
    hideContent(isOpen){
      this.blockContent = isOpen;
      //////console.log("isOpen", this.blockContent)
    },
    clearPropsProductRoute(){
      console.log("11111111111111111111");
      this.productToRoute = null
    },
    async routeToProduct(value){
      console.log("1111133333");
      this.productToRoute = value
      await this.$router.push('menu')
    },
    setCategory(name){
      console.log(name);

      switch(name){
        case 'Доставка':
          this.pageDelivery = 1
          break;

        case 'Самовывоз':
          this.pageDelivery = 2
          break;


        case 'О компании':
          this.pageAbout = 1
          
          break;

        case 'Руководство':
          this.pageAbout = 2
          break;

        case 'История':
          this.pageAbout = 3
          break;

        case 'Преимущества':
          this.pageAbout = 4
          break;

        case 'Пресс-релизы':
          this.pageAbout = 5
          break;

        case 'Наши партнеры':
          this.pageAbout = 6
          break;

        case 'Контакты':
          this.pageAbout = 7
          break;
      }
      //console.log(this.PageAbout);


      if(['Доставка', 'Самовывоз'].includes(name)){
        if(name == 'Доставка'){
          this.PageDelivery = 1
        }
        if(name == 'Самовывоз'){
          //console.log('PageDelivery Самовывоз');
          this.PageDelivery = 2
        }
        //console.log(this.PageDelivery);
        return
      }
      //////console.log('app');
      if(name.name != null){
        this.$router.push('menu')
        this.category = name;
      }
    },
    
    changePageDelivery(value){
      this.pageDelivery = value
    },

    changePageAbout(value){
      this.pageAbout = value
    },
    checkSection(){
      this.pageAbout = 2
    },

    clearProps(){
      this.category = "";
    },
    setDescription(string){
      document.getElementsByName('description')[0].setAttribute('content', string)
      // console.log(document.getElementsByName('description')[0].getAttribute('content'));
    }
  },
  watch:{
    // category(value){
    //   //console.log(value);
    // },
    '$route.path'(){
      switch (this.$route.name){
          case 'menu':
            this.setDescription("Бургеры со 100% говядиной без заморозки на фирменных булках. И не только... ") 
            break

          case 'career':
            this.setDescription("Актуальные вакансии в ресторанах") 
            break

          case 'restaurants':
            this.setDescription(`Найдите ближайший ресторан сети "Франклинс бургер"`) 
            break

          case 'about':
            this.setDescription(`Контакты и актуальная информация о сети`) 
            break

          default:
            this.setDescription(`Мы создаём на российском рынке фаст-фуд нового уровня, чтобы готовить лучшие бургеры в городе`) 
          
            
      }
    }
  },
}
</script>
<!--style lang="css" src="@/style/normalize.css">
</style-->
<style lang="scss">
  @import '@/style/sizes.scss';
  
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #282828;
  height: 100vh;
  display: flex;
  flex-direction: column;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body{
  // min-height: 100%;
  display: flex;
  flex-direction: column;
  *{box-sizing: border-box;}
  // overflow-x:hidden;
  // margin: 0;
  width: 100vw;
  // max-width: 100vw;
  // min-height: 100%;
  margin: 0px;
  position: relative;
  // overflow-y: hidden;
  overflow-x:hidden;  /*для горизонтального*/
  
}

input{
  border: none;
  &:focus{
    border: none;
      border-color: #bdbdbd;
      outline: 0;
      // border: 2px #bdbdbd solid;  //#F2623
      box-shadow: 0px 0px 10px #a1a1a1;
  }
}

a{
  text-decoration: none;
  width: auto;
  :visited{
    color: inherit;
  }
  // height: 100%;
  // position: absolute;
}

nav {
  padding: 30px;
}

.none{
  display: none;
}



.background-modal{
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(#c18816, 80%); // #FBB119
  // backdrop-filter: blur(2px);
  z-index: 1000;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.active-route{
  *{
    // font-weight: bold;
    color: #282828;

    &.router-link-exact-active {
      // color: $mainColor;
      color: #282828;
      font-weight: 600;
    }
  }
}



.main{
  border: 1px solid red;
  padding: 20px;
  &__item{
    border: 1px solid green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_name{

    }
  }
}

.transparent{
  opacity: 0;
}

@font-face {
  font-family: "Circe"; 
  src: url("@/fonts/CeraPro.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "CeraThin"; 
  src: url("@/fonts/CeraProThin.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
} 

@font-face {
  font-family: "Arial"; 
  src: url("@/fonts/arial.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "Din Pro"; 
  // src: url("@/fonts/Din Pro.otf") format("truetype"); 
  src: url("@/fonts/Rounds Black.otf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
  font-size: 10% !important;
}

@font-face {
  font-family: "DinText"; 
  src: url("@/fonts/CeraPro.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "Din Pro"; 
  src: url("@/fonts/DinPro.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "Shadow"; 
  src: url("@/fonts/shadow-regular.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "CeraPro"; 
  src: url("@/fonts/CeraPro.ttf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

@font-face {
  font-family: "CeraMedium";
  src: url("@/fonts/CeraPro-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "CeraLight";
  src: url("@/fonts/CeraPro-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}




@font-face {
  font-family: "Cocogoose"; 
  src: url("@/fonts/cocogoose.otf") format("truetype");
  font-style: normal; 
  font-weight: normal; 
}

</style>
