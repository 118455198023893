import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useSalePoints() {
    const {api, store_id} = useApi();
    const points = ref(null)
    const getPoints = ()=>{
        return axios.get(api+`/stores/${store_id}/sale-points/`)
        .then((r) =>{
            console.log(r.data.data);
            points.value = r.data.data
        })
    }
    return {
        points, 
        getPoints,
    }
}