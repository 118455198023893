<template>
<div class="map__wrap" @click="closeMap">
    <div class="map">
        <img src="@/assets/cross.svg" class="map__close" @click="closeMap">
    </div>
    <iframe class="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A96ff5a743f4e9fffae9b9a19f9021a54345420ddbbfa841e9fd823025f031f9f&amp;source=constructor" width="500" height="400" frameborder="0"></iframe>

</div>
</template>
<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import {ref} from "vue"
export default {
    components:{
        yandexMap,
        ymapMarker,
    },
    setup(props, {emit}){
        const settings = ref({
            apiKey: 'bde340ab-5c1d-4995-994a-a791b4ac9eff',
            lang: 'ru_RU',
            coordorder: 'latlong',
            enterprise: false,
            version: '2.1',
        })
    const coords = ref([
      54.82896654088406,
      39.831893822753904,
    ])
    const closeMap = () => {
        emit('closeMap')
    }
        return{
            settings,
            coords,
            closeMap,
        }
    }

}
</script>

<style lang="scss" scoped>
    @import '@/style/sizes.scss';
    
    .ymap-container{
        height: 500px;
    }
    .map{
        position: absolute;
        border-radius: 15px;
        width: 85vw;
        height: 80vh;
        border: 1vw solid white;
        &__wrap{
            z-index: 1000;
            position: fixed;
            left:0;
            top:0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.519);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2vw;
        }
        &__divClose{
            position: relative;
            width: 100%;
            height: 100%;
        }
        &__close{
            width: 2.5vw;
            position: absolute;
            left: 2vmin;
            top: 2vmin;
            cursor: pointer;
            z-index: 11;
            color: $mainColor;
            opacity: 0.5;
        }
    }
    @media screen and (max-width:1000px){
        .map{
            width: 90vw;
            height: 75vh;
            border: 5px solid white;
            &__close{
                width: 6vw;
            }
        }
    }
</style>