import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useCategories() {

    const {api, store_id} = useApi();
    const categories = ref(null)
    const getCategories = ()=>{
            return axios.get(api+`/cp/stores/${store_id}/categories/`)
            .then((r) =>{
                categories.value = r.data.data
            })
    }
    return {
        categories, 
        getCategories,
    }
}